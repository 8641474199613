<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-06-13 16:04:03
 * @LastEditTime: 2024-09-14 11:22:36
 * @LastEditors: xiaoq
-->
<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <ul class="skip">
          <li @click="goToHome"></li>
          <li @click="goToAbout"></li>
          <li @click="goToQuestion"></li>
        </ul>
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem1" />
        <a
          class="procy"
          href="https://bsawv.subkamolplus.com/help/privacy_policy"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/subkamolplus/components/img/footer.png";
import importedImg2 from "@/page/subkamolplus/components/img/footer_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      altText: "图片",
    };
  },
  methods: {
    goToHome() {
      this.$router.push("/");
    },
    goToAbout() {
      this.$router.push("/about");
    },
    goToQuestion() {
      this.$router.push("/question");
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  margin-top: -2px;
}
.img2Box {
  position: relative;
}
.procy {
  position: absolute;
  left: 2%;
  top: 20%;
  width: 165px;
  height: 25px;
  /* border: 1px solid red; */
}

.img1Box {
  position: relative;
}
.skip {
  position: absolute;
  left: 13.5%;
  top: 14%;
  width: 1025px;
  height: 58px;
  display: flex;
  gap: 150px;
}
.skip li {
  flex: 1;
  /* border: 1px solid red; */
  border-radius: 10px;
  cursor: pointer;
}
</style>
